<template>
  <div class="card">
    <div class="container">
      <b-modal ref="addVehicleGalleryImagesModal" hide-footer>
        <addVehicleGalleryImages
          @closeAddVehicleGalleryImages="toggleAddVehicleGalleryImages()"
          @addVehicleGalleryImagesSuccess="addVehicleGalleryImagesSuccess()"
        ></addVehicleGalleryImages>
      </b-modal>
      <b-modal ref="editVehicleGalleryImagesModal" hide-footer>
        <editVehicleGalleryImages
          :editingVehicleGalleryImages="currentVehicleGalleryImages"
          @closeEditVehicleGalleryImages="toggleEditVehicleGalleryImages()"
          @editVehicleGalleryImagesSuccess="editVehicleGalleryImagesSuccess()"
        ></editVehicleGalleryImages>
      </b-modal>

      <b-modal ref="bulkAddVehicleGalleryImagesModal" hide-footer>
        <bulkAddVehicleGalleryImages
          :editingVehicleGalleryImages="currentVehicleGalleryImages"
          @closebulkAddVehicleGalleryImages="
            toggleBulkAddVehicleGalleryImages()
          "
          @bulkAddVehicleGalleryImagesSuccess="
            bulkAddVehicleGalleryImagesSuccess()
          "
        ></bulkAddVehicleGalleryImages>
      </b-modal>

      <div class="row">
        <h2>VehicleGalleryImages</h2>
      </div>
      <div class="row">
        <button
          @click="toggleAddVehicleGalleryImages()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <button
          @click="toggleBulkAddVehicleGalleryImages()"
          type="button"
          class="btn btn-success"
        >
          Bulk Add
        </button>
      </div>
      <div class="row">
        <br />
    <DataBlocks
          :showImage='true'
          @itemSelected="editItem"
          :data="items"
          :fields="['Modal','Type','Heading']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import addVehicleGalleryImages from "../../components/addVehicleGalleryImages";
import editVehicleGalleryImages from "../../components/editVehicleGalleryImages";
import bulkAddVehicleGalleryImages from "../../components/custom/bulkAddVehicleGalleryImages";
import VueNotifications from "vue-notifications";

import { Datatable } from "../../mdb/components/Datatable";
import DataBlocks from "@/components/custom/dataBlocks.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
        {
          label: "Id",
          field: "Id",
          sort: "asc",
        },
        {
          label: "ModelId",
          field: "ModelId",
          sort: "asc",
        },
        {
          label: "Image",
          field: "Image",
          sort: "asc",
        },
        {
          label: "ImageMeta",
          field: "ImageMeta",
          sort: "asc",
        },
        {
          label: "DisplayOrder",
          field: "DisplayOrder",
          sort: "asc",
        },
        {
          label: "LastUpdated",
          field: "LastUpdated",
          sort: "asc",
        },
        {
          label: "Type",
          field: "Type",
          sort: "asc",
        },
      ],
      items: [],
      addVehicleGalleryImagesOpen: false,
      editVehicleGalleryImagesOpen: false,
      bulkAddVehicleGalleryImagesOpen: false,
      currentVehicleGalleryImages: {},
    };
  },
  components: {
    addVehicleGalleryImages,
    editVehicleGalleryImages,
    bulkAddVehicleGalleryImages,
    Datatable,
    DataBlocks,
  },
  created: function () {
    this.getVehicleGalleryImages();
    this.addVehicleGalleryImagesOpen = false;
    this.editVehicleGalleryImagesOpen = false;
    this.bulkAddVehicleGalleryImagesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },

    getVehicleGalleryImages() {
      var payload = {
        success: (response) => {
          this.mapVehicleModels(response.data);
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getVehicleGalleryImages", payload);
    },
    mapVehicleModels(variants) {
      var payload = {
        success: (response) => {
          var variantsNew = [];
          for (const iterator of variants) {
            var modal = response.data.find((g) => g.Id == iterator.ModelId);
            if (modal) {
              iterator.Modal = modal.Name;
            }
            variantsNew.push(iterator);
          }

          this.items = variantsNew;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getVehicleModels", payload);
    },
    editItem(VehicleGalleryImages) {
      this.toggleEditVehicleGalleryImages();
      this.currentVehicleGalleryImages = VehicleGalleryImages;
    },
    toggleAddVehicleGalleryImages() {
      if (this.addVehicleGalleryImagesOpen) {
        this.$refs.addVehicleGalleryImagesModal.hide();
      } else {
        this.$refs.addVehicleGalleryImagesModal.show();
      }
      this.addVehicleGalleryImagesOpen = !this.addVehicleGalleryImagesOpen;
    },
    addVehicleGalleryImagesSuccess() {
      this.toggleAddVehicleGalleryImages();
      miniToastr["success"](
        "VehicleGalleryImages Added",
        "Success",
        1000,
        null
      );
      this.getVehicleGalleryImages();
    },
    toggleEditVehicleGalleryImages() {
      if (this.editVehicleGalleryImagesOpen) {
        this.$refs.editVehicleGalleryImagesModal.hide();
      } else {
        this.$refs.editVehicleGalleryImagesModal.show();
      }
      this.editVehicleGalleryImagesOpen = !this.editVehicleGalleryImagesOpen;
    },

    toggleBulkAddVehicleGalleryImages() {
      if (this.bulkAddVehicleGalleryImagesOpen) {
        this.$refs.bulkAddVehicleGalleryImagesModal.hide();
      } else {
        this.$refs.bulkAddVehicleGalleryImagesModal.show();
      }
      this.bulkAddVehicleGalleryImagesOpen =
        !this.bulkAddVehicleGalleryImagesOpen;
    },

    editVehicleGalleryImagesSuccess() {
      this.toggleEditVehicleGalleryImages();
      miniToastr["success"](
        "VehicleGalleryImages Edited",
        "Success",
        1000,
        null
      );
      this.getVehicleGalleryImages();
    },
  },
};
</script>
<style scoped>
.VehicleGalleryImagesThumbnail {
  height: 50px;
}
</style>
