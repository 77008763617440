<template>
  <div class='container'>
    <h2>Add Gallery Images</h2>
    <button @click='toggleView()' type='button' class='btn btn-success'>Done</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <div class='row'>
      <div class=' col-md-4'><label class='' for='ModelId'>ModelId</label><input class=' form-control' id='ModelId'
          name='ModelId' v-model='vehicleGalleryImagesModel.ModelId' placeholder='ModelId' tabindex='0' type='text'
          value=''>
        <div class=''><small></small></div>
      </div>
      <br />
    </div>
    <div class='row'>
      <div class='col-md-4'>
        <label class for='Image'>Upload Gallery Images</label>
        <div v-if="showMultiFileUploader()">
          <ImageGalleryUploader @success="imageGalleryUploaderSuccess" :ModelId='vehicleGalleryImagesModel.ModelId' />
        </div>
      </div>
    </div>
    <button @click='toggleView()' type='button' class='btn btn-success'>Done</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br />
    <br />
  </div>

</template>
<script>
  import _ from 'lodash';
  import FileUploader from '@/components/custom/FileUploader.vue';
  import ImageGalleryUploader from '@/components/custom/ImageGalleryUploader';
  import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
  const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
  };
  miniToastr.init({
    types: toastTypes
  });
  export default {
    name: 'addVehicleThreeSixtyImages',
    created: function () {},
    data() {
      return {
        vehicleGalleryImagesModel: {},
        editingImage: false,
        uploadedImageData: {},
        uploadedImageUrl: null,
      }
    },
    components: {
      FileUploader,
      ImageGalleryUploader
    },

    methods: {
      imageGalleryUploaderSuccess(){
        miniToastr['success']('Vehicle Gallery Images Added', 'Success', 1000, null);
      },
      showMultiFileUploader() {
        if (this.vehicleGalleryImagesModel.ModelId) {
          return true;
        }
        return false;
      },
      toggleView: function () {
        this.$emit('closebulkAddVehicleGalleryImages')
      },
      updateImageData: function (value) {
        this.uploadedImageData = value;
      },
      updateImageUrl: function (value) {
        this.uploadedImageUrl = value;
      },
      editImage: function () {
        this.editingImage = !this.editingImage;
      },
      updateImage: function () {
        this.editingImage = !this.editingImage;
      },
      getImageSource(data) {
        if (this.uploadedImageUrl) {
          return this.uploadedImageUrl;
        }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
          if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
          } else {
            return data.Image;
          }
        }
        return '';
      },
      isNull: function (obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
          return true;
        }
        return false;
      },
      done: function () {
        this.$emit('closeAddVehicleThreeSixtyImages')
      }
    },
    watch: {
      vehicleGalleryImagesModel(val) {
        this.$store.state.currentThreeSixtyItem = val
      }
    },
  }

</script>
<style scoped>
  .previewImage {
    max-height: 100px;
  }

</style>
