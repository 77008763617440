<template>
  <div class="container">
    <h2>Edit VehicleGalleryImages</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button
      @click="deleteVehicleGalleryImages()"
      type="button"
      class="btn btn-danger"
    >
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="ModelId">ModelId</label>

        <VehicleModelSelect v-model="editingVehicleGalleryImages.ModelId" />
      </div>
      <div class="col-md-4">
        <image-uploader label="image" v-model="editingVehicleGalleryImages.Image"> </image-uploader>
      
      </div>
      <div class="col-md-4">
        <label class for="ImageMeta">ImageMeta</label>
        <input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="editingVehicleGalleryImages.ImageMeta"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="DisplayOrder">DisplayOrder</label>
        <input
          class="form-control"
          id="DisplayOrder"
          name="DisplayOrder"
          v-model="editingVehicleGalleryImages.DisplayOrder"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingVehicleGalleryImages.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Type">Type</label>
        <select
          style="display: block !important"
          class="form-control"
          id="Type"
          name="Type"
          v-model="editingVehicleGalleryImages.Type"
        >
          <option value="Exterior">Exterior</option>
          <option value="Interior">Interior</option>
        </select>
      </div>

      <div class="col-md-4">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingVehicleGalleryImages.Heading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import VehicleModelSelect from "@/components/custom/VehicleModelSelect.vue";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
import ImageUploader from '@/components/custom/ImageUploader.vue';
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditVehicleGalleryImages",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
    VehicleModelSelect,
    ImageUploader,
  },
  created() {
    this.originalData = JSON.parse(
      JSON.stringify(this.editingVehicleGalleryImages)
    );
    this.ImageBytes = this.editingVehicleGalleryImages.Image;
  },

  props: ["editingVehicleGalleryImages"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditVehicleGalleryImages");
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingVehicleGalleryImages));
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("editVehicleGalleryImagesSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("editVehicleGalleryImages", payload);
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deleteVehicleGalleryImages: function () {
      var payload = {
        data: this.editingVehicleGalleryImages,
        success: (response) => {
          this.$emit("editVehicleGalleryImagesSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteVehicleGalleryImages", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
