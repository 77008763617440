<template>
  <div class="container">
    <h2>Add A VehicleGalleryImages</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="ModelId">ModelId</label
        ><VehicleModelSelect v-model="newVehicleGalleryImages.ModelId" />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">

          <image-uploader label="image" v-model="newVehicleGalleryImages.Image"> </image-uploader>
       
      </div>
      <div class="col-md-4">
        <label class="" for="ImageMeta">ImageMeta</label
        ><input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="newVehicleGalleryImages.ImageMeta"
          placeholder="ImageMeta"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="DisplayOrder">DisplayOrder</label
        ><input
          class="form-control"
          id="DisplayOrder"
          name="DisplayOrder"
          v-model="newVehicleGalleryImages.DisplayOrder"
          placeholder="DisplayOrder"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="LastUpdated">LastUpdated</label
        ><input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="newVehicleGalleryImages.LastUpdated"
          placeholder="LastUpdated"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class for="Type">Type</label>
        <select
          style="display: block !important"
          class="form-control"
          id="Type"
          name="Type"
          v-model="newVehicleGalleryImages.Type"
        >
          <option value="Exterior">Exterior</option>
          <option value="Interior">Interior</option>
        </select>
      </div>

      <div class="col-md-4">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="newVehicleGalleryImages.Heading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <br />
    </div>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import VehicleModelSelect from "@/components/custom/VehicleModelSelect.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  name: "addVehicleGalleryImages",
  created: function () {},
  data() {
    return {
      newVehicleGalleryImages: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
    VehicleModelSelect,
  },
  methods: {
    toggleView: function () {
      this.$emit("closeAddVehicleGalleryImages");
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    save: function () {
      var payload = {
        data: this.newVehicleGalleryImages,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("addVehicleGalleryImagesSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("addVehicleGalleryImages", payload);
    },
  },
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
